import React from 'react';
import { graphql } from 'gatsby';

// components
import Head from '@components/Head';
import { LayoutContainer, LayoutPreviewContainer } from '@components/Layout';

// assets
import { WelcomePromoBar } from '@global/data/promobarsData';

import { IStrapiProps } from '@components/StrapiParser/types';
import BodyParser from '@components/StrapiParser';

const IndexPage = ({ data }: any) => {
  return (
    <main>
      <Head
        title="Your College & Career Readiness Journey | Pathful"
        description="Pathful is the complete college and career readiness system that helps students decide what they want to be and how to prepare for life beyond the classroom."
        tag="classroom, career, internship, learning, curriculum, career exploration, discovery, planning, path, work-based learning, career readiness, education outreach, real-world learning, course planner, graduation plans, life skills, digital lessons, virtual lessons, virtual job shadow, job shadow, job shadowing, career videos, career technical education, career placement, K12 internship opportunities"
        url="https://pathful.com/"
        imageUrl="https://pathful.com/pathful_preview.png"
      />
      {data?.isPreview ? (
        <LayoutPreviewContainer
          promobarConfig={WelcomePromoBar}
          activeLogo={data?.cms?.landing?.data?.attributes?.Layout?.Topbar_Scroll_Logo}
          topbarBorderColor={data?.cms?.landing?.data?.attributes?.Layout?.Topbar_Details_Color}
          topbarMenuId={data?.cms?.landing?.data?.attributes?.Layout?.Topbar_Menu}
          footerMenuId={data?.cms?.landing?.data?.attributes?.Layout?.Footer_Menu}
          floatingTopbar
        >
          {data?.cms?.landing?.data?.attributes?.Body[0]?.Paragraph}
          {data?.cms?.landing?.data?.attributes?.Body &&
            data?.cms?.landing?.data?.attributes?.Body.map(
              (strapiComponent: IStrapiProps['component'], index: number) => (
                <BodyParser key={strapiComponent.id} component={strapiComponent} index={index} />
              ),
            )}
        </LayoutPreviewContainer>
      ) : (
        <LayoutContainer
          promobarConfig={WelcomePromoBar}
          activeLogo={data?.cms?.landing?.data?.attributes?.Layout?.Topbar_Scroll_Logo}
          topbarBorderColor={data?.cms?.landing?.data?.attributes?.Layout?.Topbar_Details_Color}
          topbarMenuId={data?.cms?.landing?.data?.attributes?.Layout?.Topbar_Menu}
          footerMenuId={data?.cms?.landing?.data?.attributes?.Layout?.Footer_Menu}
          floatingTopbar
        >
          {data?.cms?.landing?.data?.attributes?.Body[0]?.Paragraph}
          {data?.cms?.landing?.data?.attributes?.Body &&
            data?.cms?.landing?.data?.attributes?.Body.map(
              (strapiComponent: IStrapiProps['component'], index: number) => (
                <BodyParser key={strapiComponent.id} component={strapiComponent} index={index} />
              ),
            )}
        </LayoutContainer>
      )}
    </main>
  );
};

export const pageQuery = graphql`
  query ($id: ID!) {
    allFile(filter: { relativeDirectory: { eq: "pages/homepage/assets" } }) {
      edges {
        node {
          name
          relativePath
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
    cms {
      landing(id: $id) {
        data {
          attributes {
            Layout {
              Topbar_Details_Color
              Topbar_Scroll_Logo {
                data {
                  id
                  attributes {
                    url
                  }
                }
              }
              Topbar_Menu
              Footer_Menu
            }
            Body {
              ...AccordionTabs
              ...AccordionImageTabs
              ...AnimatedPathCurved
              ...AnimatedPathStraight
              ...Announcement
              ...Cards
              ...CompactQuote
              ...Counter
              ...CounterAboutUs
              ...CTABanner
              ...CtaSection
              ...CtaSectionJunior
              ...FaqComponent
              ...FlippingCards
              ...Glossary
              ...HeroAboutUs
              ...HeroBanner
              ...HeroConnect
              ...HeroExplore
              ...HeroHome
              ...HeroJunior
              ...HeroGlossary
              ...HeroPlanner
              ...HorizontalTabsV1
              ...HorizontalTabsV2
              ...HorizontalTabsV3
              ...Journey
              ...OurBoard
              ...LottieMap
              ...LottieSenseSelf
              ...LoginPageCards
              ...MultiLogo
              ...PartnersLogos
              ...PartnersLogosWithExternalTitle
              ...PieChart
              ...PricingPlans
              ...ResourceLinkPanel
              ...ScreenRobot
              ...SingleColumnGrid
              ...SingleQuote
              ...ComparisonSlider
              ...TabConnect
              ...TabExplore
              ...Tools
              ...TopicCardPanel
              ...TwoColumnsGrid
              ...VerticalTabsV1
              ...VideoGrid
              ...VideoJunior
              ...VideoVideo
              ...ThinImage
              ...VideoSection
              ...VideoThinPanel
              ...HowItWorksHeroConnect
              ...HowItWorksHeroExplore
              ...HowItWorksHeroJunior
              ...HowItWorksHeroPlanner
            }
          }
        }
      }
    }
  }
`;

export default IndexPage;
